import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export interface ActionsCellRendererParams extends ICellRendererParams {
  menuActionOptions: MenuActionOptions [];
}

export interface MenuActionOptions {
  onClickCallback: (node: RowNode) => void;
  label:string;
  hide?:boolean;
}

@Component({
  selector: 'app-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.scss'],
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {
  public params: ActionsCellRendererParams;

  constructor() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  onClick($event: MouseEvent,menuOption) {
    if (menuOption.onClickCallback instanceof Function) {
       menuOption.onClickCallback(this.params.node);
    }
  }

  refresh(): boolean {
    return false;
  }
}
