<xpo-shell class="main-shell">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name  fxHide fxShow.gt-sm>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <ng-container *ngFor="let route of userRoutes$ | async; let i = index">
      <button *ngIf="!route.children" type="button" [routerLink]="route.path" routerLinkActive="xpo-selected" class="menu-button">
        {{ route.label }}
      </button>
      <button *ngIf="route.children" type="button" [matMenuTriggerFor]="linkItemMenu"  class="menu-button">
        {{ route.label }}
      </button>
      <mat-menu #linkItemMenu="matMenu">
        <button
          *ngFor="let children of route.children"
          type="button"
          mat-menu-item
          [routerLink]="children.path"
          routerLinkActive="xpo-selected"
          class="menu-button"
        >
          {{ children.label }}
        </button>
      </mat-menu>
    </ng-container>
  </xpo-header-navigation>

  <xpo-header-utilities-actions>
    <xpo-auth-user-profile>  </xpo-auth-user-profile>
  </xpo-header-utilities-actions>
  <router-outlet></router-outlet>
  <app-notification></app-notification>
</xpo-shell>
<app-footer [buildVersion]="build" (releaseNotes)="releaseNotesSelectedAndOpen()"></app-footer>
