import { NgModule } from '@angular/core';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { ErrorPipe } from './pipes/error/error.pipe';
import { YesNoPipe } from './pipes/yes-no/yes-no.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { ReplaceCommaDirective } from './directives/replace-comma.directive';
import { SnackBarMessageComponent } from './components/snack-bar-message/snack-bar-message.component';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from '../app-notification.component';
import { GridAppliedFiltersComponent } from './components/grid-applied-filters/grid-applied-filters.component';
import { AppliedFiltersDisplayPipe } from './components/grid-applied-filters/helper/applied-filer.pipe';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { IconButtonCellRendererComponent } from './components/cell-renderers/icon-button-cell-renderer/icon-button-cell-renderer.component';
import { LinkCellRendererComponent } from './components/cell-renderers/link-cell-renderer/link-cell-renderer.component';
import { StatusCellRendererComponent } from './components/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { XpoChipsModule } from '@xpo-ltl/ngx-ltl-core/chips';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoBusyLoaderModule } from '@xpo-ltl/ngx-ltl-core/busy-loader';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { BooleanCellRendererComponent } from './components/cell-renderers/boolean-cell-renderer/boolean-cell-renderer.component';
import { CheckboxCellRendererComponent } from './components/cell-renderers/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionsCellRendererComponent } from './components/cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    FooterComponent,
    ReleaseNotesComponent,
    GridAppliedFiltersComponent,
    // Pipes/Directives
    ErrorPipe,
    YesNoPipe,
    ReplaceCommaDirective,
    SnackBarMessageComponent,
    NotificationComponent,
    AppliedFiltersDisplayPipe,
    IconButtonCellRendererComponent,
    LinkCellRendererComponent,
    StatusCellRendererComponent,
    BooleanCellRendererComponent,
    CheckboxCellRendererComponent,
    ActionsCellRendererComponent
  ],
  imports: [
    CommonModule,
    XpoFooterModule,
    XpoIconModule,
    MatIconModule,
    XpoDialogModule,
    XpoButtonModule,
    XpoCardModule,
    MatChipsModule,
    XpoChipsModule,
    MatMenuModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    XpoStatusIndicatorModule,
    XpoConfirmDialogModule,
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    FooterComponent,
    ReleaseNotesComponent,
    SnackBarMessageComponent,
    NotificationComponent,
    GridAppliedFiltersComponent,
    XpoBusyLoaderModule,
    // Pipes/Directives
    ReplaceCommaDirective,
    AppliedFiltersDisplayPipe,
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
