<h1 mat-dialog-title>
  Edge Artemis Update
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>

<div mat-dialog-content>
  <p>A new version of Edge Artemis has been released since the last time you logged on.</p>
  <p>Check out the Release Notes for details!</p>
</div>

<div mat-dialog-actions>
  <button mat-flat-button xpoButton (click)="viewReleaseNotes()" data-test="release-notes__view-now">View Now</button>
  <button mat-stroked-button xpoButton (click)="close()" data-test="release-notes__dismiss">Dismiss</button>
</div>
