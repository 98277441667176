import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationApiService, SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SdkRatingModule } from '@xpo-ltl-2.0/sdk-rating';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './dialogs/dialog.module';
import { SharedModule } from './shared/shared.module';
import { YesNoPipe } from './shared/pipes/yes-no/yes-no.pipe';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { UserPreferencesService } from './services/user-preferences/user-preferences.service';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { SdkDynamicPricingModule } from '@xpo-ltl/sdk-dynamicpricing';
import { RfpStandaloneGuard } from './rfp-standalone/rfp-standalone-interceptor';
import { SdkTariffManagementModule } from '@xpo-ltl/sdk-tariffmanagement';
import { SdkRfpMgmtModule } from '@xpo-ltl/sdk-rfpmgmt';
import { SdkCustomerModule, SdkCustomerModule as SdkCustomer2Module } from '@xpo-ltl-2.0/sdk-customer';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoApplicationSwitcherModule } from '@xpo-ltl/ngx-ltl-core/application-switcher';
import { XpoBusyLoaderModule } from '@xpo-ltl/ngx-ltl-core/busy-loader';
import { SetDefaultValuePipe } from './shared/pipes/set-default-value/set-default-value.pipe';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { HttpClientModule } from '@angular/common/http';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');
  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    XpoButtonModule,
    XpoCardModule,
    XpoIconModule,
    XpoDialogModule,
    XpoShellModule,
    XpoLtlAuthUiModule,
    MatMenuModule,
    XpoSnackBarModule,
    XpoBusyLoaderModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkCustomerModule,
    SdkCustomer2Module,
    SdkRatingModule,
    SdkDynamicPricingModule,
    SdkRfpMgmtModule,
    XpoApplicationSwitcherModule,
    SdkTariffManagementModule,
    SdkUserPreferenceModule,
    XpoIconModule,
    XpoFooterModule,
    SdkRfpMgmtModule,
    MatSelectModule,
    MatMomentDateModule,
    HttpClientModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.PRC_AGM }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    LocationApiService,
    NotificationService,
    UserPreferencesService,
    //pipes
    DatePipe,
    YesNoPipe,
    CurrencyPipe,
    SetDefaultValuePipe,
    RfpStandaloneGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
