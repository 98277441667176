import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export interface CheckboxCellRendererParams extends ICellRendererParams {
  onCheckboxChange: (newValue: boolean, node: RowNode) => void;
  enabledCallback?: (node: RowNode) => boolean;
}

@Component({
  selector: 'checkbox-cell-renderer',
  templateUrl: './checkbox-cell-renderer.component.html',
  styleUrls: ['./checkbox-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
  private params: CheckboxCellRendererParams;

  checkboxControl = new UntypedFormControl();

  agInit(params: CheckboxCellRendererParams): void {
    this.params = params;
    this.checkboxControl.setValue(this.params.value);
    this.updateControlState();

    this.checkboxControl.valueChanges.subscribe(newValue => {
      this.params.setValue(newValue);

      if (this.params.onCheckboxChange instanceof Function) {
        this.params.onCheckboxChange(newValue, this.params.node);
      }
    });
  }

  refresh(params: CheckboxCellRendererParams): boolean {
    this.params = params;
    this.checkboxControl.setValue(this.params.value, { emitEvent: false });
    this.updateControlState();
    return true;
  }

  updateControlState(): void {
    if (this.params.enabledCallback && !this.params.enabledCallback(this.params.node)) {
      this.checkboxControl.disable({ emitEvent: false });
    } else {
      this.checkboxControl.enable({ emitEvent: false });
    }
  }
}
