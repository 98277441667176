export enum AppRoutes {
  ROOT = '/',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  RULESET_VIEW = 'ruleset_view',
  RULESET_DETAIL = 'ruleset-detail',
  FORM = 'form',
  LIST = 'list',
  DETAIL_PAGE = 'detail-page',
  AGREEMENT_LIST = 'agreement-list',
  FSC_TEMPLATE = 'fsc-template',
  ZONE_POINTS = 'zone-points',
  FUEL_PRICE = 'fuel-price',
  AGREEMENTS = 'agreements',
  ACCESSORIAL_SERVICES = 'accessorial-services',
  ACCESSORIAL_GROUPS = 'accessorial-groups',
  PL_DOCS = 'pl-docs',
  STANDALONE_RFP = 'standalone-rfp',
  STANDALONE_RULESET = 'standalone-ruleset',
  SALES_ID = 'by-sales-id',
  RULESET_BUILDER = 'ruleset-builder',
  WORK_QUEUE = 'work-queue',
  AGREEMENT = 'agreement',
  RULESET='ruleset',
  CUSTOMER_HIERARCHY = 'customer-hierarchy',
  AUDIT = 'audit',
  AUDIT_TABS = 'audit-tabs',
}
