<button
  class="actions"
  mat-icon-button
  [matMenuTriggerFor]="actions"
  matTooltip="Actions"
  matTooltipPosition="above"
>
  <xpo-icon iconName="more-vert"></xpo-icon>
</button>
<mat-menu #actions="matMenu">
  <div *ngFor="let menuOption of params.menuActionOptions">
  <button  mat-menu-item (click)="onClick($event,menuOption)" *ngIf="!menuOption.hide">
    {{menuOption.label}}
  </button>
  </div>
</mat-menu>
