import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CompleteRuleSet, GetPricingAgreementResp } from '@xpo-ltl/sdk-pricingagmtmgmt';

export interface RulesetParams {
  agreementId: number;
  ruleSetSequenceNbr: number;
  effectiveDate?: string;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RulesetDetailService {
  rulesetParams: RulesetParams;
  rulesetFormDetail: UntypedFormGroup;
  pricingAgreementRulesetDetails: GetPricingAgreementResp;
  rulesetSequenceNbr: number;
  // individualRulesetDetail: CompleteRuleSet;

  getIndividualRulesetDetail(): CompleteRuleSet {
    return this.pricingAgreementRulesetDetails?.completeRuleSets?.find(
      (completeRuleset) => completeRuleset?.ruleSet?.ruleSetSequenceNbr === this.rulesetSequenceNbr
    );
  }
}
