import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { RulesetDetailService, RulesetParams } from '../../services/ruleset-detail.service';
import { AppRoutes } from '../../shared/enums/app-routes.enum';

@Component({
  selector: 'app-request-ruleset-dialog',
  templateUrl: './request-ruleset-dialog.component.html',
  styleUrls: ['./request-ruleset-dialog.component.scss'],
})
export class RequestRulesetDialogComponent implements OnInit {
  agreementParams: UntypedFormGroup;
  selectedDate: Date;
  readonly completeDataInd = true;
  error: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RequestRulesetDialogComponent>,
    private rulesetDetailService: RulesetDetailService,
    private router: Router
  ) {}

  ngOnInit() {
    this.createForm();
    this.dialogRef.backdropClick().subscribe(() => this.cancel());
  }

  get agreementId() {
    return this.agreementParams.controls.agreementId.value;
  }

  get routeOptions() {
    return {
      ruleSetSequenceNbr: this.agreementParams.controls.ruleSetSequenceNbr.value,
      effectiveDate: this.agreementParams.controls.effectiveDate.value
        ? this.agreementParams.controls.effectiveDate.value?.format('MM/DD/YYYY')
        : '',
      completeDataInd: this.completeDataInd,
    };
  }

  get rulesetParams() {
    return this.rulesetDetailService.rulesetParams;
  }

  set rulesetParams(params: RulesetParams) {
    this.rulesetDetailService.rulesetParams = params;
  }

  search(): void {
    this.router.navigate([AppRoutes.RULESET_VIEW, AppRoutes.DETAIL_PAGE, this.agreementId], {
      queryParams: this.routeOptions,
    });
    this.dialogRef.close();
  }

  cancel(): void {
    this.router.navigate([AppRoutes.AGREEMENT_LIST]);
    this.dialogRef.close();
  }

  createForm() {
    const { agreementId, ruleSetSequenceNbr, effectiveDate, error } = this.rulesetParams || {};

    this.agreementParams = new UntypedFormGroup({
      agreementId: new UntypedFormControl(agreementId, [Validators.required]),
      ruleSetSequenceNbr: new UntypedFormControl(ruleSetSequenceNbr, [Validators.required]),
      effectiveDate: new UntypedFormControl(effectiveDate ? moment(effectiveDate, 'MM/DD/YYYY') : ''),
    });

    this.error = error;
  }
}
